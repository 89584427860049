@import "../../../styles/variables";

.PariScreen {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $black05;
    backdrop-filter: blur(4px);
    padding: 24px;
    width: 100%;

    .title {
      font-weight: 900;
      font-size: 32px;
      text-align: center;
    }

    p {
      margin: 24px 0 0;
    }

    a {
      color: $purple;
    }
  }

  .input {
    margin: 16px 0 0;
    padding: 7px 12px;
    border: 1px solid grey;
    background-color: transparent;
    font-size: 22px;
    font-weight: 700;
    border-radius: 10px;
  }

  .button {
    margin: 16px 0 0;
    flex-shrink: 0;
    padding: 7px 12px;
    border-radius: 10px;
    background-color: $purple;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    transition: all 0.2s ease-in-out;

    &:disabled {
      opacity: 0.6;
    }
  }

  .error {
    margin: 16px 0;
    color: red;
    text-align: center;
  }

  .success {
    margin: 16px 0;
    color: green;
    text-align: center;
  }
}
