@import '../../../styles/variables';

.wrap {
  width: 100%;
  background-color: $black05;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.item {
  display: flex;
}

.button { 
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  opacity: .6;
}

.buttonActive {
  opacity: 1;
}

.icon {
  margin-bottom: 4px;
  width: 24px;
  height: auto;
}