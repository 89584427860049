.content {
  height: calc(100vh - 63px);
  overflow: scroll;
  background-image: url(./assets/images/background2.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.contentHome {
  background-image: url(./assets/images/background2.png);
}