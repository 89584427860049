@import "../../../../../styles/variables";

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.content {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: #460455;
  border-radius: 10px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}

.daysList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'day1 day2 day3 day10 day10' 'day4 day5 day6 day10 day10' 'day7 day8 day9 day10 day10';
  margin-bottom: 20px;
  gap: 5px;
}

.dayCard {
  position: relative;
  width: 100%;
  height: 100%; 
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  overflow: hidden;
}

.dayCardCompleted {
  opacity: .7;
}

.dayCardCurrent {
  -webkit-box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.2);
}

.dayCardBlocked {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    right: 0;
    bottom: 0;
  }
}

.checkIcon {
  width: 20px;
  height: auto;
}

.dayCard1 {
  grid-area: day1;
}

.dayCard2 {
  grid-area: day2;
}

.dayCard3 {
  grid-area: day3;
}

.dayCard4 {
  grid-area: day4;
}

.dayCard5 {
  grid-area: day5;
}

.dayCard6 {
  grid-area: day6;
}

.dayCard7 {
  grid-area: day7;
}

.dayCard8 {
  grid-area: day8;
}

.dayCard9 {
  grid-area: day9;
}

.dayCard10 {
  grid-area: day10;

  .coinIcon {
    width: 15px;
    margin-right: 2px;
  }

  .dayTitle,
  .dayReward {
    font-size: 20px;
  }
}


.coinIcon {
  width: 10px;
  height: auto;
  margin-right: 1px;
}

.dayTitle {
  font-weight: 700;
  font-size: 14px;
}

.dayReward {
  font-weight: 700;
  font-size: 14px;
}

.btn {
  overflow: visible;
  width: 100%;
  border-radius: 10px;
  background-color: #17a748;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}