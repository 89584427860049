@import '../../../styles/variables';

.wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.cardWrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $black05;
  backdrop-filter: blur(4px);
  padding: 25px;
  width: 100%;
}

.title {
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  margin-bottom: 25px;
}

.worksTitle {
  font-size: 20px;
  margin-bottom: 25px;
}

.steps {
  position: relative;
  padding-left: 20px;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 20px;
  //   left: 0;
  //   bottom: 20px;
  //   display: block;
  //   width: 2px;
  //   background-color: $white;
  //   transform: translateX(-50%);
  // }
}

.itemTitle {
  font-size: 18px;
  margin-bottom: 5px;
}

.itemText {
  font-size: 16px;
  color: #dadada;
}

.item {
  position: relative;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &::before {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.button {
  width: 100%;
  border-radius: 10px;
  background-color: $purple;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.friendCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.friendName {
  font-size: 18px;
  font-weight: 700;
}

.paginateWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(0,0,0,0.2);
  padding: 5px;
  border-radius: 5px;
  margin-top: -5px;
}

.activePaginateItem {
  background-color: #dadada;
  border-radius: 15px;
  color: black;
  padding: 5px 10px
}