@import "../../../styles/variables";

.wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 0;
}

.cardsWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  flex-grow: 1;
}

.card {
  border-radius: 10px;
  background-color: $black05;
  backdrop-filter: blur(4px);
  padding: 25px;
  width: 100%;
}

.profile {
  margin-bottom: 25px;
  align-self: flex-start;
}

.profileInner {
  display: flex;
  align-items: center;
}

.profileInfo {
  display: flex;
  flex-direction: column;
}

.avatar {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 50%;
  background-image: url(./images/avatar.svg);
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 20px auto;
  background-position: 50% 50%;
  margin-right: 10px;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profileName {
  font-size: 16px;
  font-weight: 500;
}

.balance {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
}

.bitcoinIcon {
  width: 10px;
  height: auto;
  margin-right: 5px;
}

.drop {
  margin-bottom: 15px;
}

.dropInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
}

.logo {
  margin-bottom: 20px;
}

.dropTitle {
  font-size: 22px;
  font-weight: 900;
}

.dropText {
  font-size: 16px;
}

.farming {
  width: 100%;
  border-radius: 10px;
  background-color: $purple;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.farmingProgressBar {
  position: absolute;
  height: 100%;
  background-color: $black05;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
}

.bitcoinIconSmall {
  width: 20px;
  height: auto;
  margin-right: 3px;
}

.farmingBalance {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.farmingBalanceValue {
  font-size: 18px;
  margin-bottom: -2px;
}

.time {
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
}

.appLink {
  overflow: visible;
  width: 100%;
  border-radius: 10px;
  background-color: $purple;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 16px;
}

.farmingButton {
  overflow: visible;
  width: 100%;
  border-radius: 10px;
  background-color: $purple;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.farmingClaim {
  background-color: #17a748;
}

.serverWrap {
  width: 100vw;
  transform: translateX(-15px);
  margin: auto;
}

.serverInner {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: relative;
}

@keyframes rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes brightnessAnimation {
  from {
    filter: brightness(0.7);
  }
  to {
    filter: brightness(1.5);
  }
}

@keyframes serverAnimation {
  from {
    filter: brightness(1);
  }
  to {
    filter: brightness(1.5);
  }
}

.circleWrap {
  z-index: -1;
  display: block;
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(./images/bg.webp);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 50% 50%;
  height: 100vh;
  width: 100vw;
  filter: brightness(0.5);
  transition: filter 0.5s ease;
}

.circleWrapActive {
  filter: brightness(0.7);
  animation: brightnessAnimation 3s 0.5s linear infinite alternate-reverse;
}

.server {
  width: 70%;
  height: auto;
  object-fit: contain;
  position: relative;
  filter: brightness(0.5);
  transition: filter 0.5s ease;
  animation: serverAnimation 3s 0.5s linear infinite alternate-reverse;
}

.serverActve {
  filter: brightness(1);
}

.serverLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: auto;
  object-fit: contain;
  filter: brightness(0.5);
  transition: filter 0.5s ease;
}

.serverLogoActive {
  filter: brightness(1);
  animation: rotating 4s 0.5s linear infinite;
}
