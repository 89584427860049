@import "../../../styles/variables";

.wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.questionBtn {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.questionIcon {
  margin: 0 0 0 8px;
  width: 24px;
  height: 24px;
}

.rouletteWrap {
  display: flex;
  margin-bottom: auto;
  justify-content: center;
}

.spinButton {
  width: 100%;
  border-radius: 10px;
  background-color: $purple;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;

  &:disabled {
    opacity: 0.6;
  }
}

.claimButton {
  background-color: #17a748;
}

.balanceTitle {
  font-size: 16px;
}

.balance {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
}

.bitcoinIcon {
  width: 10px;
  height: auto;
  margin-right: 5px;
}

.rewardInfo {
  margin-bottom: auto;
  font-size: 20px;
  text-align: center;
}

.rewardValue {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}

.rewardLogo {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
}

.modalInner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modalContent {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $black05;
  backdrop-filter: blur(4px);
}

.modalText {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input {
  padding: 7px 12px;
  border: 1px solid #fff;
  background-color: transparent;
  font-weight: 700;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  width: 100%;
}

.questionModal {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modalOpen {
  opacity: 1;
  visibility: visible;
}

.modalContent {
  position: relative;
}

.closeBtn {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}
