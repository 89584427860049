@import './variables';

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  color-scheme: only dark;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  font-family: "Euclid", sans-serif;
  font-weight: 400;
  color: $white;
  background-color: $black;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input,
select,
textarea,
button {
  font: inherit;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: $white;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lock-body {
  overflow: hidden;
  height: 100vh;
}