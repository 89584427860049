@font-face {
  font-family: Euclid;
  font-weight: 400;
  src: url('../assets/fonts/EuclidGalactic-2G.woff2') format('woff2');
}
@font-face {
  font-family: Euclid;
  font-weight: 700;
  src: url('../assets/fonts/EuclidGalactic-4G.woff2') format('woff2');
}
@font-face {
  font-family: Euclid;
  font-weight: 900;
  src: url('../assets/fonts/EuclidGalactic-5G.woff2') format('woff2');
}
