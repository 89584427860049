@import '../../../styles/variables';

.wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.inner {
  max-height: 100%;
  border-radius: 10px;
  background-color: $black05;
  backdrop-filter: blur(4px);
  padding: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.title {
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  margin-bottom: 25px;
}

.text {
  text-align: center;
  margin-bottom: 25px;
  font-size: 18px;
}

.list {
  height: 100%;
  overflow: auto;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.listWrap {
  flex-grow: 1;
  max-height: calc(100% - 137px);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.5);
  }
}

.itemDescription {
  margin-right: 10px;
}

.itemText {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.itemTextLarge {
  font-size: 30px;
}

.itemReward {
  font-size: 18px;
}

.startButton {
  flex-shrink: 0;
  padding: 7px 12px;
  border-radius: 10px;
  background-color: $purple;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.itemReward {
  font-size: 18px;
}

.button {
  flex-shrink: 0;
  padding: 7px 12px;
  border-radius: 10px;
  background-color: $purple;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.buttonDisabled {
  background-color: $black05;

}

.input {
  padding: 7px 12px;
  border: 1px solid grey;
  background-color: transparent;
  font-size: 22px;
  font-weight: 700;
  border-radius: 10px;
  max-width: 50%;
}

.itemTextSpecial {
  text-align: center;
}

.specialRemaning {
  font-weight: 700;
  margin-bottom: 10px;
}